<template>
  <div>
    <Carousel />
    <Netred />
    <Mmerce/>
    <Brand/>
  </div>
</template>
<script>
import Carousel from "@/components/pages/case/Carousel.vue";
import Netred from "@/components/pages/case/Netred.vue";
import Mmerce from "@/components/pages/case/Mmerce.vue";
import Brand from "@/components/pages/case/Brand.vue";

export default {
  components: {
    Carousel,
    Netred,
    Mmerce,
    Brand
  },
  data() {
    return {};
  },
};
</script>
<style scoped></style>
