<template >
  <div class="brand">
    <div class="brand_box">
      <div class="brand_top">
        <p>品牌孵化</p>
        <p>BRAND INCUBATION</p>
      </div>
      <el-carousel class="content">
        <el-carousel-item
          class="content_botton"
          v-for="(item, index) in content"
          :key="index"
        >
          <div class="lise_top">
            <p>{{ item.lise }}</p>
            <p>
              {{ item.text }}
            </p>
            <p>
              {{ item.texti }}<span>{{ item.arr }}</span>
            </p>
            <p>
              {{ item.textl }}<span>{{ item.arl }}</span>
            </p>
            <p>
              {{ item.textr }}<span>{{ item.top }}</span>
            </p>
          </div>
          <div class="lise_botton">
            <img :src="item.img" alt="" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: [
        {
          lise: "1七匹狼&昆仑虚|网红带货营销模式",
          text:
            " 昆仑虚与七匹狼达成深度合作，旗下全矩阵高颜值KOL以“男人男装”差异化定位,全网直播带货，流量曝光，实现七匹狼新热潮。",
          texti: " 累计曝光量达",
          textl: "互动量达 ",
          textr: " 七匹狼成为淘宝男装外套类",
          arr: "1242万",
          arl: "3万+",
          top: "TOP1",
          img: require("@/assets/imgs/study/qpl.png"),
        },
        {
          lise: "2七匹狼&昆仑虚|网红带货营销模式",
          text:
            " 昆仑虚与七匹狼达成深度合作，旗下全矩阵高颜值KOL以“男人男装”差异化定位,全网直播带货，流量曝光，实现七匹狼新热潮。",
          texti: " 累计曝光量达",
          textl: "互动量达 ",
          textr: " 七匹狼成为淘宝男装外套类",
          arr: "1242万",
          arl: "3万+",
          top: "TOP1",
          img: require("@/assets/imgs/study/qpl.png"),
        },
        {
          lise: "3七匹狼&昆仑虚|网红带货营销模式",
          text:
            " 昆仑虚与七匹狼达成深度合作，旗下全矩阵高颜值KOL以“男人男装”差异化定位,全网直播带货，流量曝光，实现七匹狼新热潮。",
          texti: " 累计曝光量达",
          textl: "互动量达 ",
          textr: " 七匹狼成为淘宝男装外套类",
          arr: "1242万",
          arl: "3万+",
          top: "TOP1",
          img: require("@/assets/imgs/study/qpl.png"),
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.brand {
  width: 100%;
  height: 800px;
  .brand_box {
  max-width: 1125px;
    margin: 0 auto;
    .brand_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: unset;
        color: #333333;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: 300;
        color: #3c3c3c;
      }
    }
    .content {
      margin-top: 115px;
      max-width: 1125px;
      height: 450px;
      .content_botton {
        height: 450px;
        cursor: pointer;
        .lise_top {
          width: 450px;
          height: 448px;
          float: left;
          p:nth-child(1) {
            font-size: 30px;
            font-family: unset;
            letter-spacing: 4px;
            line-height: 35px;
            font-weight: 500;
            color: #333333;
          }
          p:nth-child(2) {
            width: 447px;
            font-size: 23px;
            letter-spacing: 3px;
            font-weight: unset;
            color: #333333;
            line-height: 25px;
            margin-top: 60px;
            margin-bottom: 108px;
          }
          p:nth-child(5) {
            margin: 0px;
          }
          p {
            font-size: 23px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            letter-spacing: 2px;
            margin-bottom: 20px;
            span {
              color: #ef4d4d;
            }
          }
        }
        .lise_botton {
          width: 558px;
          height: 448px;
          float: right;
        }
      }
    }
  }
}
</style>