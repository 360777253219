<template>
  <div class="mmerce">
    <div class="mmerce_box">
      <div class="mmerce_top">
        <p>电商孵化</p>
        <p>ONLINE RETAILERS</p>
      </div>
      <div class="mmerce_button">
        <div class="content" v-for="(item, index) in content" :key="index">
          <div class="content_lis">
            <img :src="item.img" alt="" />
          </div>
          <div class="content_text">
            <p>{{ item.text }}</p>
            <p>
              {{ item.textl }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/study/ds_l.png"),
          text: "辣风芹",
          textl:
            "新疆文化名小吃，全国超300家线下门店，主营:辣风芹炒米粉，酱香炒年糕等特色美食",
        },
        {
          img: require("@/assets/imgs/study/ds_j.png"),
          text: "疆脾气旗舰店",
          textl:
            "新疆文化名小吃，全国超300家线下门店，主营:辣风芹炒米粉，酱香炒年糕等特色美食",
        },
        {
          img: require("@/assets/imgs/study/ds_y.png"),
          text: "粤与优品商城",
          textl: "好货有好评，严选坚果，开店一年已是美食领域TOP级品牌",
        },
      ],
    };
  },
  methods: {
  },
};
</script>
<style scoped lang="less">
.mmerce {
  width: 100%;
  margin-bottom: 200px;
  .mmerce_box {
    max-width: 1200px;
    margin: 0 auto;
    .mmerce_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: unset;
        color: #333333;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: 300;
        color: #3c3c3c;
      }
    }
    .mmerce_button {
      margin-top: 130px;
      .content {
        margin: 0 auto;
        .content_lis {
          width: 355px;
          height: 355px;
          display: flex;
          border-radius: 10px;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 6%);
          margin: 0 auto;
          cursor: pointer;
        }
        .content_lis:hover {
          position: relative;
          top: -25px;
          box-shadow: 0px 5px 10px rgba(0, 0, 0, 10%);
        }
        .content_text {
          margin-top: 40px;
          text-align: center;
          p:nth-child(1) {
            font-size: 30px;
            font-weight: unset;
            color: #333333;
          }
          p:nth-child(2) {
            width: 320px;
            margin: 0 auto;
            letter-spacing: 2px;
            font-size: 18px;
            line-height: 25px;
            font-weight: 300;
            margin-top: 25px;
            color: #333333;
          }
        }
      }
      .content:nth-child(1) {
        float: left;
      }

      .content:nth-child(2) {
        float: right;
      }
    }
  }
}
</style>

