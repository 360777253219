<template>
  <!-- 轮播图 -->
  <el-carousel class="nnel" :autoplay="false">
    <el-carousel-item
      class="nnel_img"
      v-for="(item, inedx) in content"
      :key="inedx"
    >
      <img class="imgl" :src="item.img" alt="" />
      <div class="position">
        <img class="img" :src="item.imgl" />
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/study/banner.png"),
          imgl: require("@/assets/imgs/study/baner.png"),
        },
        {
          img: require("@/assets/imgs/study/banner.png"),
          imgl: require("@/assets/imgs/study/baner.png"),
        },
        {
          img: require("@/assets/imgs/study/banner.png"),
          imgl: require("@/assets/imgs/study/baner.png"),
        },
        {
          img: require("@/assets/imgs/study/banner.png"),
          imgl: require("@/assets/imgs/study/baner.png"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.nnel,
.nnel_img,
.nnel_img > .imgl {
  min-width: 1200px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  min-height: 755px;
}

.imgl {
  position: absolute;
}
.position {
  position: relative;
  left: 0px;
  top: 415px;
  margin: 0 auto;
  width: 745px;
  .img {
    text-align: center;
    overflow: hidden;
  }
}
</style>



