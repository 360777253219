<template>
  <div class="netred">
    <div class="netred_box">
      <div class="netred_top">
        <p>网红孵化</p>
        <p>INTERNET CELEBRITY</p>
      </div>
      <div class="netred_bottom">
        <div class="content_top">
          <img class="position" src="@/assets/imgs/study/zs.png" alt="" />
          <div class="i"><p>红人培训课程</p></div>
          <img src="@/assets/imgs/study/zs1.png" alt="" />
          <img src="@/assets/imgs/study/zs2.png" alt="" />
        </div>
        <div class="content_botton">
          <div class="botton_left">
            <div class="ima">
              <img src="@/assets/imgs/study/t_zs.png" alt="" />
              <img src="@/assets/imgs/study/t_zs1.png" alt="" />
            </div>
            <img class="left_img" src="@/assets/imgs/study/t_zs2.png" alt="" />
          </div>
          <div class="botton_right">
            <div class="content_aiel">
              <img src="@/assets/imgs/study/r_zs3.png" alt="" />
            </div>
            <div class="right_content">
              <img src="@/assets/imgs/study/r_zs.png" alt="" />
              <img src="@/assets/imgs/study/r_zs1.png" alt="" />
              <img src="@/assets/imgs/study/r_zs2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="less">
.netred {
  width: 100%;
margin-top: 100px;
  margin-bottom: 250px;
  .netred_box {
    max-width: 978px;
    margin: 0 auto;
    .netred_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: unset;
        color: #333333;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        letter-spacing: 2px;
        font-size: 20px;
        font-weight: 300;
        color: #3c3c3c;
      }
    }
    .netred_bottom {
      margin: 0 auto;
      overflow: hidden;
      margin-top: 60px;
      .content_top {
        width: 978px;
        height: 326px;
        overflow: hidden;
        background-color: violet;
        border-radius: 10px;
        .position {
          position: absolute;
        }
        .i {
          width: 326px;
          height: 326px;
          display: inline-block;
          position: relative;
          left: 0;
          top: 0;
          cursor: pointer;
          text-align: center;

          p {
            font-size: 30px;
            font-weight: 500;
            color: #000000;
            line-height: 36px;
            line-height: 326px;
            display: none;
          }
        }
        :hover p {
          display: block;
        }
        .i:hover {
          width: 326px;
          height: 326px;
          display: inline-block;
          background: #ffffff;
          opacity: 0.4;
          position: relative;
          left: 0;
          top: 0;
          cursor: pointer;
        }
      }
      .content_botton {
        overflow: hidden;
        border-radius: 10px;
        margin-top: 10px;
        .botton_left {
          float: left;
          border-radius: 10px;
          .ima {
            width: 200px;
            float: left;
            img:nth-child(2) {
              float: left;
              margin-top: 11px;
            }
          }
          .left_img {
            float: right;
            margin-left: 12px;
          }
        }

        .botton_right {
          float: right;
          border-radius: 10px;
          .right_content {
            width: 240px;
            float: left;
            img:nth-child(2) {
              float: right;
            }
            img:nth-child(1) {
              float: left;
            }
            img:nth-child(3) {
              margin-top: 11px;
            }
          }
          .content_aiel {
            float: right;
            img {
              margin-left: 12px;
            }
          }
        }
      }
    }
  }
}
</style>

